<script setup lang="ts">
import { computed } from 'vue';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';

const testimonials = computed(() => ([
  {
    name: "Phoebe Henderson",
    content: "“Seriously, this tool changed my life. I used to spend so much time worrying about my social accounts. Now I leave all the hard work to Incredibility.”",
    image: 'images/testimonials/phoebe.jpg'
  },
  {
    name: "Trish Frankland",
    content: "“I don’t know where I’d be without Incredibility. It saves me so much time, costs a fraction of what I was spending on a ‘social media expert’, and has dramatically improved the content I share.”",
      image: 'images/testimonials/trish.jpg'
  },
  {
    name: "Brian Bryce",
    content: "“Social media has never really been my thing. Now, thanks to Incredibility, it doesn’t have to be! They’ve got my socials under control, leaving me free to get out and do the things I’m really good at.”",
      image: 'images/testimonials/brian.jpg'
  }
]))
</script>

<template>
  <section class="testimonials">
    <div class="container">
      <h2>The secret weapon the top realtors are using</h2>

      <Swiper :modules="[Pagination]" :pagination="{ clickable: true }" :slides-per-view="1" :space-between="0"
        :loop="true">
        <SwiperSlide v-for="item in testimonials" :key="item.name">
          <div class="testimonial">
            <div class="avatar">
                <img :src="asset(item.image)" class="rounded-full" />
            </div>

            <div class="content">
              <p class="review">{{ item.content }}</p>
              <span class="name">{{ item.name }}</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.testimonials {
  h2 {
    @apply leading-[120%] text-center mb-28;
    @apply text-3xl;

    @screen md {
      @apply text-4xl;
    }

    @screen lg {
      @apply text-46;

    }
  }

  :deep(.swiper) {
    @apply pb-32;
    --swiper-pagination-bottom: 0px;
    --swiper-pagination-color: theme('colors.site.black');
    --swiper-pagination-bullet-size: 9px;
  }

  .testimonial {
    @apply flex flex-col justify-between gap-10 items-start;

    @screen md {
      @apply flex-row gap-20 items-center;
    }

    .avatar {
      --size: 100px;
      @apply rounded-full bg-[#D9D9D9];
      min-width: var(--size);
      min-height: var(--size);

      @screen md {
        --size: 150px;
      }

      @screen lg {
        --size: 200px;
      }
    }

    .content {
      @apply flex flex-col;

      .review {
        @apply leading-normal text-site-black mb-6;
        @apply text-xl;

        @screen md {
          @apply text-28;
        }
      }

      .name {
        @apply text-site-black font-semibold;
        @apply text-sm;

        @screen md {
          @apply text-xl;
        }
      }
    }
  }
}
</style>