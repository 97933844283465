<script setup lang="ts">
import HeroBanner from '../Components/Brands/HeroBanner.vue';
import SocialMetrics from '../Components/Brands/SocialMetrics.vue';
import WebinarCTA from '../Components/Brands/WebinarCTA.vue';
import WinningTheGame from '../Components/Brands/WinningTheGame.vue';
import GetEngaging from '../Components/Brands/GetEngaging.vue';
import TwoColumnBanner from '../Components/Shared/TwoColumnBanner.vue';
import BookingCTA from '../Components/Brands/BookingCTA.vue';
import Testimonials from '../Components/Brands/Testimonials.vue';
import FooterBanner from '../Components/Brands/FooterBanner.vue';
import SiteHead from "../Components/SiteHead.vue";
import GetEngagingSummary from "../Components/Brands/GetEngagingSummary.vue";
import FullTechStack from "../Components/Brands/FullTechStack.vue";

type TwoBannerProps = InstanceType<typeof TwoColumnBanner>['$props']
const saveTimeBanner: TwoBannerProps = {
    title: 'Frequency Counts',
    description: `Today’s social media algorithms mean any given post is only ever seen by a small
percentage of followers. That’s why the best agents are posting upwards of 4-5 times a
week. Of course, that volume of content is a daunting proposition for many, which is why
Incredibility’s library of evergreen content is so valuable.`,
    banner: {
        alignment: 'right',
        image: 'images/site/save-time-banner.png',
        label: 'Post scheduled automatically',
        labelIcon: 'images/site/icons/check.svg',
    }
}

const saveMoneyBanner: TwoBannerProps = {
    title: 'Balance Matters',
    description: `Some agents are great at sharing listings. Some love posting selfies and personal updates
while others prefer sharing articles and offering market insights. But few agents are good at
everything. A great social account will feature a blend of posts covering everything from
new listings and recent sales to buyer and seller tips, staging advice, industry terms and
candid insights.`,
    // button: {
    //   text: 'See how',
    //   link: '/',
    // },
    banner: {
        alignment: 'left',
        image: 'images/site/save-money-banner.png',
        label: 'Company template applied',
        labelIcon: 'images/site/icons/sparkles.svg',
    }
}

const growReachBanner: TwoBannerProps = {
    title: 'Time Is Precious',
    description: `Social media is important – surveys show that the vast majority of prospects will examine an
agent’s social accounts before engaging their services, and would view a poorly maintained
page as a red flag. But the best agents are successful because they great at building
relationships – in-person, in real life. Not stuck in front of their computer for hours every
week trying to create social media content.`,
    // button: {
    //   text: 'Get started now',
    //   link: '/',
    // },
    banner: {
        alignment: 'right',
        image: 'images/site/grow-reach-banner.png',
        label: '87 people liked your post!',
        labelIcon: 'images/site/icons/thumb-down.svg',
    }
}
</script>

<template>
    <div>

        <site-head title="The ultimate social media solution for realtors"/>

        <HeroBanner/>

        <SocialMetrics/>

        <WinningTheGame/>

        <WebinarCTA/>


        <GetEngaging/>



        <TwoColumnBanner v-bind="saveTimeBanner"/>

        <TwoColumnBanner v-bind="saveMoneyBanner"/>

        <TwoColumnBanner v-bind="growReachBanner" class="grow-reach-banner"/>

        <GetEngagingSummary />

        <FullTechStack />

        <BookingCTA/>

<!--        <Testimonials/>-->

<!--        <FooterBanner/>-->
    </div>
</template>

<style scoped lang="postcss">
.grow-reach-banner :deep(.image-label) {
    @apply !translate-y-20;
}
</style>