<template>


    <div class="lg:w-1/3 w-4/5 pb-10 pt-5 mx-auto">
        <form @submit.prevent="submit" class="relative">

            <div v-if="honeypot.enabled" :name="`${honeypot.nameFieldName}_wrap`" style="display:none;">
                <input type="text" v-model="form[honeypot.nameFieldName]" :name="honeypot.nameFieldName" :id="honeypot.nameFieldName" />
                <input type="text" v-model="form[honeypot.validFromFieldName]" :name="honeypot.validFromFieldName" />
            </div>


            <div v-if="success" class="text-xl text-white">
                Thanks for signing up, your first tip will be sent to your shortly. 🚀🏡
            </div>

            <div class="relative" v-else>
                <input type="text" class="bg-white h-14 w-full px-4 pr-20 rounded-md focus:outline-none hover:cursor-pointer" v-model="form.email">
                <button type="submit" :disabled="form.processing"
                    class="h-10 rounded bg-inc-baby-blue absolute top-2 text-sm right-2 px-3 text-white hover:bg-inc-blue lg:text-base text-xs">
                    Subscribe Now
                </button>
            </div>

        </form>
    </div>
</template>

<script>

import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';

export default {


    props: {
        honeypot: Object
    },

    setup(props){


        const success = ref(false);

        const form = useForm({
            email: null,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            [props.honeypot.nameFieldName]: '',
            [props.honeypot.validFromFieldName]: props.honeypot.encryptedValidFrom,
        })


        function submit() {


            form.post("/subscribe", {
                preserveScroll: true,
                onSuccess: () => success.value = true,
            })

        }

        return {
            form,
            submit,
            success
        }

    },
    methods: {

    }

}

</script>