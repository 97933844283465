<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { Link } from '@inertiajs/vue3';
import { usePage, router } from '@inertiajs/vue3'

const page = usePage();

const currentPageComponent = ref(page.component);
const isOnHome = computed(() => currentPageComponent.value === 'Home');
const isOnBrands = computed(() => currentPageComponent.value === 'Brands');
const isOnFeatures = computed(() => currentPageComponent.value === 'Features');
const isOnPricing = computed(() => currentPageComponent.value === 'Pricing');
const isOnLogin = computed(() => currentPageComponent.value === 'Login');
const isOnDemo = computed(() => currentPageComponent.value === 'Demo');

// listen navigation events & update current page component to active link
router.on('navigate', (event) => {
  currentPageComponent.value = event.detail.page.component;
  userToggleNav.value = false;
})

onMounted(() => {
  window.addEventListener('resize', onResize)
  onResize();
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize)
})

const windowWidth = ref(window.innerWidth);
const userToggleNav = ref(false);
const canShowMenuBtn = computed(() => windowWidth.value < 800);
const showingNav = computed(() => userToggleNav.value || !canShowMenuBtn.value);
function onResize() {
  userToggleNav.value = false;
  windowWidth.value = window.innerWidth;
}
function toggleHeaderNav() {
  userToggleNav.value = !userToggleNav.value;
}
</script>
<template>
  <header :class="{ 'shadow-site-header': !isOnHome && !isOnBrands, 'text-white': isOnHome || isOnBrands }">
    <div class="header-container" :class="{ 'bg-site-black !fixed top-0 w-full': showingNav && canShowMenuBtn }">
      <Link href="/">
      <img v-if="isOnHome || isOnBrands" :src="asset('images/site/logo.svg')" alt="Incredibility Logo" />
      <img v-else :src="asset('images/site/logo-black.svg')" alt="Incredibility Logo black" />
      </Link>
      <!-- show header links on desktop -->
      <div class="header-nav" :class="{ active: showingNav }">
        <div class="header-links">
          <Link v-if="canShowMenuBtn" :href="route('site.home')" :class="{ active: isOnHome }">Home</Link>
          <Link :href="route('site.features')" :class="{ active: isOnFeatures }">Features</Link>
          <Link :href="route('site.pricing')" :class="{ active: isOnPricing }">Pricing</Link>
          <a :href="route('login')">Log In</a>
          <a :href="route('register')">Sign Up</a>
        </div>
        <a href="https://calendly.com/tim_incredibility/30min" target="_blank" :class="{ active: isOnDemo }" class="demo-btn">
        Get a demo
        </a>
      </div>
      <!-- show menu icon -->
      <div v-if="canShowMenuBtn" class="cursor-pointer" @click="toggleHeaderNav">
        <img :src="asset('images/site/menu.svg')" />
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
header {
  @apply z-[50];

  .header-container {
    @apply relative flex flex-row justify-between items-center py-8;
    @apply px-site-mobile z-[51];

    @screen lg {
      @apply px-site-desktop;
    }


    .header-nav {
      @apply fixed inset-0 top-[84px] z-50 bg-site-black text-white;
      @apply flex flex-col items-center justify-center flex-wrap gap-[51px];
      @apply transition-all duration-300 ease-in-out;
      @apply -translate-x-full;

      &.active {
        @apply translate-x-0;
      }

      @screen md {
        @apply static translate-y-0 flex-row justify-end bg-transparent text-inherit;
      }

      .header-links {
        @apply flex flex-col items-center gap-[51px] whitespace-nowrap relative;

        @screen md {
          @apply flex-row;
        }
      }

      .demo-btn {
        @apply border-2 border-site-green rounded-lg py-2 px-5 font-medium;
      }
    }
  }
}
</style>