<script setup lang="ts">
import { computed } from 'vue';
import TwoColumnBanner from '../Shared/TwoColumnBanner.vue';

type TwoBannerProps = InstanceType<typeof TwoColumnBanner>['$props']

const banners = computed<TwoBannerProps[]>(() => ([
  {
    title: 'Multiple posts, every week',
    description: `Each and every week you’ll get 2x posts from our huge library of general content, covering everything from buyer and seller tips, staging and décor, renovations, industry terms and, of course, agent promotion. Written, designed and scheduled to your accounts without you lifting a finger, every piece of content you receive is targeted at the specific audience(s) you’re trying to connect with, and talks to the specific property types you specialize in.`,
    banner: {
      alignment: 'right',
      image: 'images/site/features/img-resources-1.png',
    }
  },
  {
    title: 'Sharing listings, celebrating wins',
    description: `The Incredibility system collects live data from the country’s major property portals. Every time you launch a new listing, we receive a notification. We’ll automatically create a New Listing post and schedule it to your social accounts – you don’t need to notify us of the listing, or request a post. You’ll receive an email letting you know we’ve scheduled a post, at which point you’ll have the chance to push it live immediately or make any of your own amends. And the same thing happens every time you turn a live listing to a closed deal.`,
    banner: {
      alignment: 'left',
      image: 'images/site/features/img-resources-2.png',
    }
  },
  {
    title: 'Your brand, front and centre',
    description: `Success on social media is about playing the long-game, positioning yourself front-of-mind whenever a prospect decides the time’s right to partner with a realtor. And brand recognition is crucial to that. Intuitive design tools, built into our system, allow you to personalize all social templates, ensuring key branding elements feature prominently on all posts.`,
    banner: {
      alignment: 'right',
      image: 'images/site/features/img-resources-3.png',
    }
  },
  {
    title: 'Total control',
    description: `While we’re handling every element of your social media efforts, you’re still in complete control. You still own your accounts, and you can still post your own content, whenever you want. Our user-friendly dashboard gives you full visibility across every post we create and schedule for you. From there, you can edit and tweak, reschedule or even delete, as well as creating your own content directly from Incrediblity’s central social media hub.`,
    banner: {
      alignment: 'left',
      image: 'images/site/features/img-resources-4.png',
    }
  },
]));
</script>

<template>
  <section class="feature-list">
  <div class="container">
    <TwoColumnBanner v-for="banner in banners" v-bind="banner" />
    </div>
  </section>
</template>

<style scoped lang="postcss">
.feature-list {
  .container {
    @apply flex flex-col;

    h4 {
      @apply text-xl leading-tight mb-4;

      @screen md {
        @apply text-32 mb-6;
      }
    }
  }
}
</style>
