<script setup lang="ts">
import { Link } from "@inertiajs/vue3"
import PricingBenefits from '../Shared/PricingBenefits.vue';
import {inject} from "vue";

const $cookies = inject('$cookies');
const freeTrialUnlocked = $cookies.get('unlocked_free_trial');
</script>

<template>
  <section>
    <div class="container">
      <h1>The ultimate social media tool for realtors</h1>
      <p>Dramatically enhance the quality and volume of content you share on your social media channels without lifting a
        finger, moving a mouse, or expending a brain-cell!</p>
      <!-- pricing card -->
      <div class="pricing-box text-center">
        <p class="text-center font-medium text-2xl text-site-black">
          <span class="text-4xl md:text-5xl lg:text-55 font-medium">$150</span>/month
        </p>
        <PricingBenefits />
        <a :href="route('register')" class="rounded-lg px-30 text-white pt-3 pb-2 bg-site-blue mt-8 inline-block transition-transform duration-500 ease-in-out hover:scale-105">Sign up for a <template v-if="freeTrialUnlocked">two week</template><template v-else>two week</template> free trial</a>
        <div class="text-site-black text-center text-xs italic">(No credit card required)</div>
      </div>
      <!-- booking demo -->
      <div class="booking-demo">
        <h4>Still got questions?</h4>
        <p>
          <a href="https://calendly.com/tim_incredibility/30min" target="_blank">Book a demo</a> with us so we can show you what all the fuss is about!
        </p>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
section {
  @apply pt-20 mt-4;

  @screen sm {
    @apply pt-28;
  }

  @screen md {
    @apply pt-32;
  }

  @screen lg {
    @apply pt-44;
  }

  .container {

    &>h1 {
      @apply text-center mb-8;
      @apply text-4xl;

      @screen md {
        @apply text-42;
      }
    }

    &>p {
      @apply text-center max-w-4xl mx-auto mb-14;
    }

    .pricing-box {
      @apply rounded-xl bg-white;
      @apply max-w-[560px] w-full mx-auto relative space-y-6;
      box-shadow: 0px 8px 25px rgba(0, 36, 80, 0.2);
      @apply py-12 px-10 mb-14;

      @screen md {
        @apply px-12 mb-20;
      }

      @screen lg {
        @apply px-14 mb-28;
      }

      button {
        @apply mx-auto;
      }
    }

    .booking-demo {
      @apply mt-2 pb-2 text-center;
      @apply mb-14;

      @screen md {
        @apply mb-20;
      }

      @screen lg {
        @apply mb-28;
      }

      h4 {
        @apply mb-4 text-xl;

        @screen md {
          @apply text-30;
        }
      }

      p {
        @apply mt-1 text-site-black;

        a {
          @apply font-medium text-site-blue;
        }
      }
    }
  }
}
</style>