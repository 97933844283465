<script setup lang="ts">
import {Link} from '@inertiajs/vue3';
import {inject} from "vue";

const $cookies = inject('$cookies');
const freeTrialUnlocked = $cookies.get('unlocked_free_trial');

</script>

<template>
    <section class="hero-banner" no-padding>
        <div class="max-w-[1500px] mx-auto">
            <div class="content">
                <h1>The ultimate <br/>social media solution for real estate brands</h1>
                <p>The only system in the world with every tool your business, your offices and your agents
                    need to dominate the social media game.
                </p>

                <a href="https://calendly.com/tim_incredibility/30min" class="rounded-lg px-30 text-inc-blue drop-shadow-none py-3 bg-white mt-8 inline-block font-semibold transition-transform duration-500 ease-in-out hover:scale-105" target="_blank">Book a call with our Founder</a>
            </div>
            <div class="image" :style="{ backgroundImage: 'url(' + asset('images/site/hero-banner.png') + ')' }"/>

            <div class="pt-24 md:pt-4">
                <div class="bg-inc-gray-lighter absolute bottom-4 rounded p-3 max-w-[1500px] w-5/6 text-center" v-if="freeTrialUnlocked">
                    <h2 class="text-3xl font-bold ">Free trial unlocked</h2>
                    <p class="text-lg mt-2">
                        You have unlocked an exclusive two week free trial of Incredibility. <a href="/register" class="underline">Sign up today</a> and your free trial will be automatically applied to your account.
                    </p>
                </div>
            </div>

        </div>
    </section>
</template>

<style scoped lang="postcss">
.hero-banner {
    background: linear-gradient(103.88deg, #2B4666 0.52%, #002450 49.81%, #061B36 99.11%);
    @apply pt-[280px] pb-40 md:pb-60 xl:pb-[280px] px-site-mobile lg:px-site-desktop -mt-40 relative;

    .content {
        //--text-shadow-color: theme('colors.site.gray');
        @apply text-white w-full relative z-[1];
        //text-shadow: -1px -1px 0 var(--text-shadow-color), 1px -1px 0 var(--text-shadow-color), -1px 1px 0 var(--text-shadow-color), 1px 1px 0 var(--text-shadow-color);

        @screen md {
            @apply max-w-[50%];
        }

        @screen lg {
            @apply max-w-[613px];
        }

        h1 {
            @apply text-3xl md:text-4xl xl:text-6xl text-white leading-[110%] font-semibold mb-5;
        }

        p {
            @apply font-medium text-sm lg:text-17 leading-normal;
        }

        button {
            @apply text-site-black bg-white mt-10 font-semibold;
        }
    }

    .image {
        @apply absolute right-0 top-40 md:top-0 w-1/2 h-full;
        @apply bg-contain bg-no-repeat bg-center opacity-75 md:opacity-100;
    }
}
</style>