<script setup lang="ts"></script>

<template>
    <section class="wining-the-game"
             :style="{ backgroundImage: 'url(' + asset('images/site/winning-banner.png') + ')' }">
        <div class="container">
            <h2 class="">The real estate industry’s first true ‘full tech
                stack’ of social media tools</h2>

            <div class="grid grid-cols-2 gap-10 mt-12">
                <div class="item">
                    <div class="item-image">
                        <img :src="asset('images/site/shoe.svg')"/>
                    </div>
                    <div class="item-content">
                        <h4>Brand &amp; Office Level</h4>

                        <ul>
                            <li>
                                A network linking Head Office, Branches and Agents
                            </li>
                            <li>
                                Collaborate with agents and offices to execute your brand’s social strategies
                            </li>
                            <li>
                                Create content for offices and agents to share, or schedule it directly to their social accounts on their behalf
                            </li>
                            <li>
                                Share libraries of pre-written content, pre-prepared brand templates and pre-
                                approved imagery
                            </li>
                            <li>
                                Upskill stakeholders with libraries of educational resources
                            </li>
                            <li>
                                Provide the tools needed to ensure all content shared complies with industry
                                regulations
                            </li>
                            <li>
                                Boost posts across your entire network and run paid campaigns
                            </li>
                            <li>
                                Track data across all levels of your organization
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item">
                    <div class="item-image">
                        <img :src="asset('images/site/trophy.svg')"/>
                    </div>
                    <div class="item-content">
                        <h4>Agent Tools</h4>
                        <ul>
                            <li>
                                A user-friendly dashboard for all social activity
                            </li>
                            <li>
                                A central hub, connected directly to your social accounts
                            </li>
                            <li>
                                Libraries of pre-written, brand-approved content
                            </li>
                            <li>
                                Asset libraries of imagery and templates
                            </li>
                            <li>
                                Intuitive tools to design and create your own content
                            </li>
                            <li>
                                Boost posts and run paid campaigns
                            </li>
                            <li>
                                View analytics and track performance
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<style scoped lang="postcss">
.wining-the-game {
    @apply bg-cover bg-no-repeat bg-center;

    h2 {
        @apply text-center text-white;
        @apply text-3xl;
        line-height: 3.5rem !important;


        @screen md {
            @apply text-4xl;
        }

        @screen lg {
            @apply text-55;
        }
    }

    .item {
        @apply flex flex-col gap-8;

        @screen md {
            @apply flex-row;
        }

        .item-image {
            @apply rounded-full w-40 h-40 min-w-[160px] min-h-[160px] flex justify-center items-center;

            img {
                @apply w-3/4 h-3/4;
            }
        }

        &:first-child .item-image {
            background: linear-gradient(90deg, rgba(0, 184, 255, 0.3) 0%, rgba(2, 207, 231, 0.3) 100%);
        }

        &:last-child .item-image {
            background: linear-gradient(270deg, rgba(0, 242, 182, 0.3) 0%, rgba(2, 220, 215, 0.3) 100%);
        }

        .item-content {
            h4 {
                @apply text-white font-semibold mb-4;
                @apply text-xl leading-tight;

                @screen md {
                    @apply text-38;
                }

            }

            p {
                @apply text-[#E9E9EC] text-sm;

                @screen md {
                    @apply text-17;
                }
            }

            ul{
                @apply text-[#E9E9EC] text-sm list-disc ml-10 mt-4;

                @screen md {
                    @apply text-17;
                }
            }

            li{
                @apply mb-3
            }
        }
    }
}
</style>