<script setup lang="ts">

</script>

<template>
    <section class="get-engaging" no-padding="">
        <div class="container mx-auto mb-20">

            <p class="caption">Incredibility does the vast majority of this work for the agent, automatically creating and
                publishing the new listing, just sold and evergreen content every week which keeps agents
                in the newsfeeds AND giving the agents the tools they need to create the icing on the cake –
                the personal posts – more quickly and easily than ever before.</p>
        </div>
    </section>


</template>

<style scoped lang="postcss">
.get-engaging {
    h2 {
        @apply !leading-tight text-center;
        @apply text-3xl;

        @screen md {
            @apply text-4xl;
        }

        @screen lg {
            @apply text-46;
        }
    }

    p.caption {
        @apply text-center mt-8;
    }

    .infos {
        @apply mt-14 pt-1 flex justify-between flex-wrap text-left gap-8 mx-auto max-w-4xl;

        @screen md {
            @apply gap-12;
        }

        @screen lg {
            @apply gap-16;
        }

        .info-item {
            @apply text-center;

            .image {
                @apply bg-site-black rounded-full flex items-center justify-center mx-auto mb-2;
                --size: theme('width.16');
                width: var(--size);
                min-width: var(--size);
                height: var(--size);
                min-height: var(--size);
            }
        }
    }
}
</style>