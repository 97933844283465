<script setup lang="ts">
import { Link } from "@inertiajs/vue3";
import { computed } from "vue";

const props = defineProps<{
  title: string;
  description: string;
  banner: {
    alignment: 'left' | 'right';
    image: string; // url or path to image
    label?: string;
    labelIcon?: string; // url or path to icon
  };
  button?: {
    text: string;
    link: string;
  };
}>();

const isLeft = computed(() => props.banner.alignment === 'left');
</script>

<template>
  <section class="two-col-banner">
    <div class="container" :class="{ 'align-left': isLeft }">
      <div class="content">
        <h4>{{ title }}</h4>
        <p>{{ description }}</p>
        <Link v-if="button" as="button" :href="button.link" data-animate="slide-left">{{ button.text }}</Link>
      </div>
      <div class="image">
        <img :src="asset(banner.image)" :alt="banner.label">
        <div v-if="banner.label" class="image-label">
          <img :src="asset(banner.labelIcon)">
          <span>{{ banner.label }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.two-col-banner .container {
  @apply items-center justify-center;
  @apply flex flex-col gap-10;

  @screen md {
    @apply grid grid-cols-2 gap-28;
  }

  .content {
    h4 {
      @apply text-3xl mb-3;

      @screen md {
        @apply text-38 mb-5;
      }
    }

    button {
      @apply bg-site-blue text-white;
    }
  }

  .image {
    @apply relative;

    .image-label {
      @apply flex items-center gap-2 rounded-3xl bg-white py-2 px-4;
      box-shadow: 0px 6px 25px rgba(0, 36, 80, 0.2);
      @apply absolute right-0 -translate-x-10 top-1/2 -translate-y-10;
    }

    img {
      @apply float-left;
    }
  }

  &.align-left {
    .content {
      @apply order-1;
    }

    .image {
      .image-label {
        @apply left-0 right-auto;
      }

      img {
        @apply float-right;
      }
    }
  }
}
</style>