<script setup lang="ts"></script>

<template>
    <section class="wining-the-game"
             :style="{ backgroundImage: 'url(' + asset('images/site/winning-banner.png') + ')' }">
        <div class="container">
            <h2 class="">Two sides of the equation</h2>

            <p class="text-[#E9E9EC] text-lg text-center max-w-3xl mx-auto mb-20 mt-3">
                Incredibility is the first system in the world to bring all the tools a brand, its offices, and
                every one of its agents need to leverage social media effectively.
            </p>
            <div class="grid grid-cols-2 gap-10">
                <div class="item">
                    <div class="item-image">
                        <img :src="asset('images/site/shoe.svg')"/>
                    </div>
                    <div class="item-content">
                        <h4>For your business</h4>
                        <p>Our unique network system links your Head Office with the social accounts of your offices,
                            and your agents, allowing you to flow information through every level of your organisation
                            (and dramatically enhance the reach of your brand in the process).</p>

                        <ul>
                            <li>
                                Create, share and even schedule social posts to all, or some, of the social accounts in
                                your network
                            </li>
                            <li>
                                Get more control than ever before over the quality (and compliance) of all content
                                posted under your brand’s umbrella
                            </li>
                            <li>
                                View data, analytics and reporting from every social account in your organisation
                            </li>
                            <li>
                                Boost content and run paid campaigns
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="item">
                    <div class="item-image">
                        <img :src="asset('images/site/trophy.svg')"/>
                    </div>
                    <div class="item-content">
                        <h4>For your agents</h4>
                        <p>Agents are busy. The last thing they need is another tool which adds to their workload.
                            Fortunately, Incredibility not only helps them increase the quality, and the frequency, of
                            their social content. It also slashes the time they have to spend doing it! Incredibility
                            gives each agent…
                        </p>
                        <ul>
                            <li>
                                Multiple ‘evergreen’ posts every single week – written, branded and scheduled to
                                their accounts for them (they literally don’t have to do a thing).
                            </li>
                            <li>
                                Automatic posts to their social accounts every time they list a new property, and
                                every time they close a deal.
                            </li>
                            <li>
                                Access to a library or pre-approved posts and imagery from Head Office.
                            </li>
                            <li>
                                A user-friendly dashboard and a range of advanced tools to help them create their
                                own additional content.
                            </li>
                            <li>
                                An array of resources to arm them with the skills and knowledge they need to take
                                their social media efforts to a whole new level.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <p class="text-[#E9E9EC] text-xl text-center max-w-4xl mx-auto mb-6 mt-20">
                This is not simply a library of content and assets for your agents to access. By connecting
                their social accounts to Incredibility, our system becomes the central hub of all their social
                activity – the can create posts, selected pre-written content, choose imagery, select and/or
                design templates, schedule and post content, boost posts and track analytics – without
                leaving Incredibility.
            </p>
        </div>
    </section>
</template>

<style scoped lang="postcss">
.wining-the-game {
    @apply bg-cover bg-no-repeat bg-center;

    h2 {
        @apply text-center text-white;
        @apply text-3xl;
        line-height: 3.5rem !important;


        @screen md {
            @apply text-4xl;
        }

        @screen lg {
            @apply text-55;
        }
    }

    .item {
        @apply flex flex-col gap-8;

        @screen md {
            @apply flex-row;
        }

        .item-image {
            @apply rounded-full w-40 h-40 min-w-[160px] min-h-[160px] flex justify-center items-center;

            img {
                @apply w-3/4 h-3/4;
            }
        }

        &:first-child .item-image {
            background: linear-gradient(90deg, rgba(0, 184, 255, 0.3) 0%, rgba(2, 207, 231, 0.3) 100%);
        }

        &:last-child .item-image {
            background: linear-gradient(270deg, rgba(0, 242, 182, 0.3) 0%, rgba(2, 220, 215, 0.3) 100%);
        }

        .item-content {
            h4 {
                @apply text-white font-semibold mb-4;
                @apply text-xl leading-tight;

                @screen md {
                    @apply text-38;
                }

            }

            p {
                @apply text-[#E9E9EC] text-sm;

                @screen md {
                    @apply text-17;
                }
            }

            ul{
                @apply text-[#E9E9EC] text-sm list-disc ml-10 mt-4;

                @screen md {
                    @apply text-17;
                }
            }

            li{
                @apply mb-3
            }
        }
    }
}
</style>