<script setup lang="ts">
const engagingInfos: Array<{ icon: string; label: string; }> = [
  {
    icon: "images/site/icons/pin.svg",
    label: 'Every agent in your office could be using Incredibility and you’d all be getting different content. Once a post from our library is published to your account, it can’t be scheduled to any other user in your region for at least two months.'
  },
  {
    icon: "images/site/icons/paint.svg",
    label: "Intuitive design tools allow you to create a suite of templates which reflect your brand, and your unique personality."
  },
  {
    icon: "images/site/icons/target.svg",
    label: "Based on information provided by you, we’ll deliver content focused on the types of properties you specialize in, and the audience types you’re trying to reach."
  }
];
</script>

<template>
  <section class="get-engaging">
    <div class="container">
      <h2>Get engaging, high-quality content uploaded to your social accounts, without lifting a finger.</h2>
      <p class="caption">Each and every week, we’ll schedule multiple posts across your Facebook, Instagram
          and Linkedin accounts. We’ll do all the hard work, coming up with content ideas, writing posts,
          sourcing imagery, adding your branding and scheduling posts on your behalf. A user-friendly
          dashboard allows you to view and edit every post we create, giving you the option to be as hands-on,
          or as hands-off, as you’d like.</p>
      <div class="infos">
        <div v-for="item in engagingInfos" :key="item.label" class="info-item">
          <div class="image">
            <img :src="asset(item.icon)" />
          </div>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.get-engaging {
  h2 {
    @apply !leading-tight text-center;
    @apply text-3xl;

    @screen md {
      @apply text-4xl;
    }

    @screen lg {
      @apply text-46;
    }
  }

  p.caption {
    @apply text-center mt-8;
  }

  .infos {
    @apply mt-14 pt-1 flex justify-between flex-wrap text-left gap-8 mx-auto max-w-4xl;

    @screen md {
      @apply gap-12;
    }

    @screen lg {
      @apply gap-16;
    }

    .info-item {
      @apply text-center;

      .image {
        @apply bg-site-black rounded-full flex items-center justify-center mx-auto mb-2;
        --size: theme('width.16');
        width: var(--size);
        min-width: var(--size);
        height: var(--size);
        min-height: var(--size);
      }
    }
  }
}
</style>