<script setup lang="ts">
const engagingInfos: Array<{ icon: string; label: string; }> = [
    {
        icon: "images/site/icons/pin.svg",
        title: "Higher Standards",
        label: 'Incredibility gives your business all the tools it needs to ensure every single agent and every single branch is leveraging social media to its full capacity while sharing quality, complaint content.'
    },
    {
        icon: "images/site/icons/paint.svg",
        title: "More Visibility",
        label: "Our network system gives you total visibility over your entire network – track activity levels\n of each agent and office, study the performance of specific posts and even manage paid campaigns across your organization."
    },
    {
        icon: "images/site/icons/target.svg",
        title: "Total Control",
        label: "With Incredibility you have as much, or as little, control over the content your team share. From creating and posting content directly to their accounts with the touch of a button, to simply provide them with the assets they need to succeed, the power is in the brand’s hands."
    }
];
</script>

<template>
    <section class="get-engaging">
        <div class="container">
            <h2>BRINGING ALIGNMENT TO YOUR SOCIAL STRATEGY</h2>
            <p class="caption">Real estate is a unique industry. On the one side, you have large organisations with
                strong
                and very visible brands. On the other side you have individual agents who are responsible
                for growing their own businesses. Ensuring marketing and growth strategies align across all
                levels of the business is a challenge.</p>
            <div class="infos">
                <div v-for="item in engagingInfos" :key="item.label" class="info-item">
                    <div class="image">
                        <img :src="asset(item.icon)"/>
                    </div>
                    <h4 class="text-xl font-bold text-inc-blue mt-3 mb-2">{{ item.title }}</h4>
                    <span>{{ item.label }}</span>
                </div>
            </div>
        </div>
    </section>


    <section class="get-engaging mx-auto mt-20" no-padding>
        <div class="container text-center mx-auto">
            <h2>EVERY TOOL YOUR AGENTS NEED</h2>

            <p class="caption">Agents are busy. You can share all the knowledge and advice in the world with them, but
                many of them will still struggle to find the time to execute. You can even hand them the
                content, either creating it in-house or subscribing to a service which produces real estate-
                specific posts and imagery. But they’ve still got to open an email, download the content,
                and post it to their pages. It’s just too hard.</p>
        </div>
    </section>
</template>

<style scoped lang="postcss">
.get-engaging {
    h2 {
        @apply !leading-tight text-center;
        @apply text-3xl;

        @screen md {
            @apply text-4xl;
        }

        @screen lg {
            @apply text-46;
        }
    }

    p.caption {
        @apply text-center mt-8;
    }

    .infos {
        @apply mt-14 pt-1 flex justify-between flex-wrap text-left gap-8 mx-auto max-w-4xl;

        @screen md {
            @apply gap-12;
        }

        @screen lg {
            @apply gap-16;
        }

        .info-item {
            @apply text-center;

            .image {
                @apply bg-site-black rounded-full flex items-center justify-center mx-auto mb-2;
                --size: theme('width.16');
                width: var(--size);
                min-width: var(--size);
                height: var(--size);
                min-height: var(--size);
            }
        }
    }
}
</style>