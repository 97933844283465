<script setup lang="ts"></script>

<template>
  <ul>
    <li>2+ social media posts each and every week</li>
    <li>A post every time you list a property for sale</li>
    <li>A post every time you close a deal</li>
    <li>Templates featuring your own branding</li>
    <li>A user-friendly dashboard giving you total control over your social media content</li>
  </ul>
</template>

<style scoped lang="postcss">
ul {
  @apply space-y-4 list-none mt-4;

  li {
    @apply text-left leading-normal pl-9 relative;
    @apply !text-sm;

    @screen md {
      @apply text-base;
    }

    @screen lg {
      @apply text-lg;
    }

    &:before {
      content: "";
      @apply inline-block bg-contain w-7 h-7 absolute left-0;
      background-image: url(/images/site/icons/check.svg);
    }
  }
}
</style>