<template>
    <Head>
        <title>{{ title ? title + ' - Incredibility' : 'Incredibility' }}</title>
        <meta name="description" :content="description">
    </Head>
</template>

<script>
import { Head } from '@inertiajs/vue3'

export default {
    components: {
        Head
    },
    props: {
        title: String,
        description: {
            type: String,
            default: "The hands-free social media tool for realtors that will unlock the full power of social media, enhancing your visibility, increasing your credibility, and helping you win more business."
        },
    },
}
</script>
