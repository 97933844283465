<template>

    <div class="h-full w-full font-sans">

        <div class="py-16 lg:py-28 w-full bg-inc-blue text-center bg-no-repeat bg-center bg-fixed"
             :style="'background-image: url(' + asset('images/brand-shape.svg') + ')'">

            <div class="pb-16 lg:pb-28">
                <img :src="asset('images/Incredibility-logo-rev.svg')" alt="logo" class="mx-auto"/>
            </div>

            <h1 class="text-white font-semibold font-heading lg:text-7xl text-5xl">Social media tools for Realtors</h1>

            <h2 class="text-inc-green font-heading lg:text-4xl text-2xl font-medium my-6">More Visibility, More
                Credibility, More Listings!</h2>

            <p class="text-white lg:text-lg sm:text-base my-16 mx-auto w-1/2">
                Struggling to constantly come up with fresh ways to engage with your prospects on social?Sign up below
                and
                get <span class=" uppercase font-bold">free content ideas</span>, delivered to your inbox every week for
                the
                next year!
            </p>

            <sign-up :honeypot="honeypot"/>

            <div class="w-1/2 mx-auto flex">
                <p class="text-xs text-gray-400 align-items:flex-end"> Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit.
                    Vivamus ultrices leo turpis, vitae commodo velit feugiat vel. Sed non tincidunt dui, ut dictum dui.
                    Phasellus
                    molestie rutrum egestas. </p>
            </div>
        </div>

        <div class="py-16 lg:py-28 w-full bg-white mx-2">
            <h2 class="text-inc-baby-blue lg:text-4xl text-2xl font-medium my-3 lg:pb-16 text-center">
                Every Monday morning, you'll get....
            </h2>
            <div class="flex grid grid-cols-2  lg:grid-cols-3 gap-y-16 lg:gap-x-16 max-w-[1400px] mx-auto">
                <div class="flex items-center">
                    <img :src="asset('images/ic-1.svg')" alt="post">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6">A fresh <span class="font-bold">post idea</span>
                    </p>
                </div>
                <div class="flex items-center">
                    <img :src="asset('images/ic-2.svg')" alt="insight">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6">Insights into <span class="font-bold">why</span>
                        this type of
                        content is so valuable
                    </p>
                </div>
                <div class="flex items-center">
                    <img :src="asset('images/ic-3.svg')" alt="step-by-step">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6"><span class="font-bold">Step-by-step</span>
                        instructions for
                        creating your post</p>
                </div>
                <div class="flex items-center">
                    <img :src="asset('images/ic-4.svg')" alt="example-text">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6"><span class="font-bold">Example text</span> to
                        use, adapt or take
                        inspiration from</p>
                </div>
                <div class="flex items-center">
                    <img :src="asset('images/ic-5.svg')" alt="expert-tip">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6"><span class="font-bold">Expert tips</span> on
                        photography and/or
                        imagery</p>
                </div>
                <div class="flex items-center">
                    <img :src="asset('images/ic-6.svg')" alt="guide">
                    <p class="ml-4 text-left text-sm md:text-lg pr-6">How-to guide to <span
                        class="font-bold">publishing</span> your
                        content</p>
                </div>
            </div>
        </div>

        <div
            class="py-16 lg:py-28 w-full bg-gray-100 text-center bg-no-repeat bg-cover bg-center bg-fixed "
            :style="'background-image: url(' + asset('images/brand-shape-grey.svg') + ')'">
            <div class="max-w-[1400px] mx-auto flex items-center">
                <div class="w-1/2 mx-3">
                    <h2 class="lg:text-5xl text-4xl font-semibold text-inc-blue text-left max-w-lg leading-normal lg:leading-normal">
                        Leverage the power of social to earn the credibility which
                        wins listings
                    </h2>
                    <button class="h-10 rounded  block bg-inc-green mt-6 px-3 hover:bg-inc-blue font-semibold">Read a
                        sample now
                    </button>
                </div>

                <div class="w-1/2 text-left">
                    <img :src="asset('images/social-posts-1x.png')" alt="logo" class=""/>
                </div>

            </div>

        </div>

        <div class="py-16 lg:py-28 w-full bg-white text-center">

            <h2 class="text-inc-baby-blue lg:text-4xl text-2xl font-semibold font-heading">What industry-leading
                realtors are
                saying</h2>

            <!--        meant to be a carosel-->
            <div class="lg:w-2/5 px-4 text-center italic mx-auto lg:pt-10 pt-5 lg:text-lg text-base">
                <p>“I finally feel like I really understand <span
                    class="font-bold">how to leverage social media properly.</span> By following the advice and tips
                    from the
                    team at Incredibility I’m now creating content that’s more engaging and truly reflects me and the
                    service I
                    offer.”</p>
            </div>

        </div>


        <div class="pt-16 lg:pt-28 pb-10 w-full bg-inc-blue text-center">

            <h2 class="text-inc-green lg:text-4xl text-2xl mb-14 font-semibold font-heading">What you can expect every
                Monday</h2>

            <!--        meant to be a carosel-->
            <div class="shadow-xl shadow-slate-100 bg-white lg:w-2/5 w-4/5 mx-auto rounded-lg text-justify">
                <p class="py-6 px-6 lg:text-base text-xs">This week we want you to do something which you can, and
                    should, try
                    and do regularly – provide your audience with some <span class="underline">compelling reasons</span>
                    to sell
                    their homes right now!</p>
                <p class="pb-6 px-6 lg:text-base text-xs">✅ <span
                    class="uppercase text-inc-green font-bold"> HERE’S WHAT TO DO: </span><br>Head
                    to
                    your dashboard and, in the caption section, write a short post focusing on one key reason <span
                        class="font-bold">now</span> is a
                    great time
                    to list. </p>
                <p class="pb-6 px-6 lg:text-base text-xs">📈 You could focus on market conditions… <br> <span
                    class="text-inc-baby-blue italic">“Right now, low interest rates, a lack of stock and high buyer demand mean it’s the perfect time to sell! If you’re interested in learning more about what the market is doing right now, don’t hesitate to get in touch.”</span>
                </p>
                <p class="pb-6 px-6 lg:text-base text-xs">🏡 Or, you could zero in on some stats in your specific corner
                    of the world… <br> <span
                        class="text-inc-baby-blue italic"> “Did you know that we’ve sold 34 houses in [Suburb] over the last 6 weeks??! And, did you know that, on average, those sales came in more than 20% over market expectation? There’s incredible demand for quality homes in this part of the city right now, and it really is the perfect time to be a seller! If you’re considering a move, give me a call anytime for an obligation-free chat.” </span>
                </p>

                <p class="pb-6 px-6 lg:text-base text-xs">
                    Once you’re finished, hit ‘Create Post’ and you’re all sorted for another week! 😀
                </p>
            </div>
            <p class="text-white text-2xl mt-28 mx-4 font-heading font-medium mb-4">Subscribe today and learn to
                leverage
                social media <span
                    class="underline">properly.</span></p>

            <sign-up :honeypot="honeypot"/>
            <p class="text-white">P.S. It's free, so what have you got to lose?!</p>
            <div class="lg:pb-12 lg:pt-8 pb-6 pt-8">
                <img :src="asset('images/Incredibility-logo-rev.svg')" alt="logo" class="mx-auto"/>
            </div>
            <div class="w-3/4 mx-auto align-text-bottom py-5">
                <p class="text-xs text-gray-400"> &#169 2023 Incredibility Ltd </p>
            </div>

        </div>


    </div>
</template>

<script>

import SignUp from "../Components/SignUp.vue";

export default {
    components: {SignUp},
    props: {
        honeypot: Object
    },

    methods: {}

}

</script>