<script setup lang="ts">
import { computed } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import BookingPlaceholder from "../Shared/BookingPlaceholder.vue";

const width = 236;
const resources = [
  'images/site/bookings/sold.png',
  'images/site/bookings/listed.png',
  'images/site/bookings/homeownership.png',
  'images/site/bookings/bathroom.png',
];
const images = computed(() => {
  return Array.from(Array(20).keys()).map((i) => {
    const index = i % 4;
    return resources[index];
  });
})
</script>

<template>
  <section class="just-like-magic" no-padding>
    <h1>Just like magic</h1>
    <p class="text-center max-w-[870px] mt-7 mx-auto">Incredibility takes all the pressure off
      your shoulders, ensuring
      your social media game is on-point while simultaneously saving you time and money. It’s hands-free, stress-free, and
      really is just like magic!</p>

    <Swiper class="booking-slider" :slides-per-view="'auto'" :space-between="35" :loop="true">
      <SwiperSlide v-for="image in images" :key="image">
        <BookingPlaceholder :image="asset(image)" />
      </SwiperSlide>
    </Swiper>
  </section>
</template>

<style scoped lang="postcss">
.just-like-magic {
  @apply pt-44 pb-20;

  @screen lg {
    @apply pb-44;
  }

  h1 {
    @apply leading-[120%] text-center;
    @apply text-4xl;

    @screen lg {
      @apply text-55;
    }
  }

  .booking-slider {
    @apply mt-20 pt-2.5 overflow-visible;

    :deep(.swiper-slide) {
      @apply w-auto;
    }
  }
}
</style>