<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  width?: number;
  image: string;
}>(), {
  width: 236
})

const widthInPixel = computed(() => `${props.width}px`)
const styles = computed(() => {
  if (props.width) {
    return {
      width: widthInPixel.value,
      minWidth: widthInPixel.value,
    }
  }
  return undefined;
})
</script>

<template>
  <div class="booking-placeholder" :style="styles">
    <div class="flex gap-2 px-3">
      <div class="avatar" />
      <div class="flex flex-col grow gap-1">
        <div class="bar w-3/4" />
        <div class="bar w-1/2" />
      </div>
    </div>
    <div class="image py-3 px-1">
      <img :src="image" alt="" />
    </div>
    <div class="flex flex-col gap-1 px-3">
      <div class="bar w-full" />
      <div class="bar w-full" />
      <div class="bar w-full" />
      <div class="bar w-full" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
.booking-placeholder {
  @apply bg-white rounded-xl py-3;
  box-shadow: 0px 8px 25px rgba(0, 36, 80, 0.2);

  .avatar {
    @apply w-6 h-6 rounded-full bg-site-shade;
  }

  .bar {
    @apply h-2 bg-site-shade;
  }

  .image {
    img {
      @apply object-contain w-full;
      height: v-bind(widthInPixel);
    }
  }
}
</style>