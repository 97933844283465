import './bootstrap';
import '../../css/site/site.css';


import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3'

import Vapor from 'laravel-vapor'
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
window.Vapor = Vapor

import VueCookies from 'vue-cookies'

import Layout from "./Layouts/DefaultLayout.vue";

import VuePlyr from '@skjnldsv/vue-plyr'
// eslint-disable-next-line import/no-unresolved,n/no-missing-import
import '@skjnldsv/vue-plyr/dist/vue-plyr.css'


createInertiaApp({
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
        let page = pages[`./Pages/${name}.vue`]
        page.default.layout = page.default.layout || Layout
        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueCookies)
            .use(VuePlyr)
            .mixin({
                methods: {
                    asset: window.Vapor.asset,
                    route: window.route,
                }
            })
            .mount(el)
    },
    progress: {
        color: '#05B8FF',
    },
})

