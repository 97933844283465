<script setup lang="ts">
import HeroBanner from '../Components/Home/HeroBanner.vue';
import SocialMetrics from '../Components/Home/SocialMetrics.vue';
import WebinarCTA from '../Components/Home/WebinarCTA.vue';
import WinningTheGame from '../Components/Home/WinningTheGame.vue';
import GetEngaging from '../Components/Home/GetEngaging.vue';
import TwoColumnBanner from '../Components/Shared/TwoColumnBanner.vue';
import BookingCTA from '../Components/Home/BookingCTA.vue';
import Testimonials from '../Components/Home/Testimonials.vue';
import FooterBanner from '../Components/Home/FooterBanner.vue';
import SiteHead from "../Components/SiteHead.vue";

type TwoBannerProps = InstanceType<typeof TwoColumnBanner>['$props']
const saveTimeBanner: TwoBannerProps = {
  title: 'Save time',
  description: `Writing captions, finding images and uploading content can be incredibly time-consuming - and then there’s the never-ending struggle to come up with fresh ideas. All of this makes it harder for you to do what you do best - getting out of the office, meeting prospects and making deals happen.`,
  // button: {
  //   text: 'Learn more',
  //   link: '/',
  // },
  banner: {
    alignment: 'right',
    image: 'images/site/save-time-banner.png',
    label: 'Post scheduled automatically',
    labelIcon: 'images/site/icons/check.svg',
  }
}

const saveMoneyBanner: TwoBannerProps = {
  title: 'Save money',
  description: `Marketing agencies and social media practitioners can easily cost over a thousand dollars a month. Of course, you could do it yourself – but what’s your time worth?`,
  // button: {
  //   text: 'See how',
  //   link: '/',
  // },
  banner: {
    alignment: 'left',
    image: 'images/site/save-money-banner.png',
    label: 'Company template applied',
    labelIcon: 'images/site/icons/sparkles.svg',
  }
}

const growReachBanner: TwoBannerProps = {
  title: 'Grow your reach',
  description: `Social media is, without question, the most cost-effective way to promote your business, and your listings, to the biggest audience possible. Incredibility will help you leverage these channels to the fullest`,
  // button: {
  //   text: 'Get started now',
  //   link: '/',
  // },
  banner: {
    alignment: 'right',
    image: 'images/site/grow-reach-banner.png',
    label: '87 people liked your post!',
    labelIcon: 'images/site/icons/thumb-down.svg',
  }
}
</script>

<template>
  <div>

    <site-head title="The ultimate social media solution for realtors"/>

    <HeroBanner />

    <SocialMetrics />

    <WebinarCTA />

    <WinningTheGame />

    <GetEngaging />

    <TwoColumnBanner v-bind="saveTimeBanner" />

    <TwoColumnBanner v-bind="saveMoneyBanner" />

    <TwoColumnBanner v-bind="growReachBanner" class="grow-reach-banner" />

    <BookingCTA />

    <Testimonials />
    
    <FooterBanner />
  </div>
</template>

<style scoped lang="postcss">
.grow-reach-banner :deep(.image-label) {
  @apply !translate-y-20;
}
</style>