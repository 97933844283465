<script setup lang="ts"></script>

<template>
  <section class="wining-the-game" :style="{ backgroundImage: 'url(' + asset('images/site/winning-banner.png') + ')' }">
    <div class="container">
      <h2 class="">More Visibility + More Credibility<br>= More Listings</h2>
      <div class="grid grid-cols-2 gap-10">
        <div class="item">
          <div class="item-image">
            <img :src="asset('images/site/shoe.svg')" />
          </div>
          <div class="item-content">
            <h4>Making the final</h4>
            <p>All of your time and energy nurturing leads and filling your funnel with prospects is spent
                with one goal in mind - to be considered when a potential client decides it is time to partner
                with a realtor. Whether that’s 6 weeks, 6 months or 6 years from now, you need to be
                front-of-mind. That comes from posting consistently, frequently sharing quality content,
                and always staying visible.</p>
          </div>
        </div>
        <div class="item">
          <div class="item-image">
            <img :src="asset('images/site/trophy.svg')" />
          </div>
          <div class="item-content">
            <h4>Winning the game</h4>
            <p>Research shows that prospects value three qualities in a realtor above all else
                – credibility/trust, track record, and industry knowledge. In the final battle
                for the business – when you WILL be pitted head-to-head against other agents –
                it’s the realtor who can prove they possess all three attributes that will emerge
                on top. And social media is the quickest, easiest way to do just that.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.wining-the-game {
  @apply bg-cover bg-no-repeat bg-center;

  h2 {
    @apply text-center text-white mb-20;
    @apply text-3xl;
  line-height: 3.5rem !important;


    @screen md {
      @apply text-4xl;
    }

    @screen lg {
      @apply text-55;
    }
  }

  .item {
    @apply flex flex-col gap-8;

    @screen md {
      @apply flex-row;
    }

    .item-image {
      @apply rounded-full w-40 h-40 min-w-[160px] min-h-[160px] flex justify-center items-center;

      img {
        @apply w-3/4 h-3/4;
      }
    }

    &:first-child .item-image {
      background: linear-gradient(90deg, rgba(0, 184, 255, 0.3) 0%, rgba(2, 207, 231, 0.3) 100%);
    }

    &:last-child .item-image {
      background: linear-gradient(270deg, rgba(0, 242, 182, 0.3) 0%, rgba(2, 220, 215, 0.3) 100%);
    }

    .item-content {
      h4 {
        @apply text-white font-semibold mb-4;
        @apply text-xl leading-tight;

        @screen md {
          @apply text-38;
        }

      }

      p {
        @apply text-[#E9E9EC] text-sm;

        @screen md {
          @apply text-17;
        }
      }
    }
  }
}
</style>