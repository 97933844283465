<template>
    <div>

        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-50 shadow-lg">
            <div class="px-4 py-5 sm:px-6">
                <h2 class="text-xl font-semibold">Realty School Affiliate Sign Ups</h2>
            </div>

            <div class="p-6">

                <div class="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300 sha">
                        <thead>
                        <tr>
                            <th scope="col"
                                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name
                            </th>
                            <th scope="col"
                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Email
                            </th>
                            <th scope="col"
                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">City
                            </th>
                            <th scope="col"
                                class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">Free Trial Ends
                            </th>
                            <th scope="col"
                                class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">Status
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(user, Idx) in users" :key="user.id">
                            <td :class="[Idx === 0 ? '' : 'border-t border-transparent', 'relative py-4 pl-4 pr-3 text-sm sm:pl-6']">
                                <div class="font-medium text-gray-900">
                                    {{ user.name }}
                                </div>

                                <div v-if="Idx !== 0" class="absolute -top-px left-6 right-0 h-px bg-gray-200"/>
                            </td>
                            <td :class="[Idx === 0 ? '' : 'border-t border-gray-200', 'relative py-4 px-3 text-sm']">
                                <div class="font-medium text-gray-900">
                                    {{ user.email }}
                                </div>
                            </td>
                            <td :class="[Idx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                <div class="font-medium text-gray-900">
                                    {{ user.city }}
                                </div>
                            </td>
                            <td :class="[Idx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-sm text-gray-500 lg:table-cell']">
                                <div class="font-medium text-gray-900">
                                    {{ user.free_trial_ends_at }}
                                </div>

                            </td>
                            <td :class="[Idx === 0 ? '' : 'border-t border-gray-200', 'px-3 py-3.5 text-center text-sm text-gray-500 lg:table-cell']">
                                <div class="font-medium text-gray-900">
                                    {{ user.subscription_status }}
                                </div>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                </div>



            </div>


        </div>

    </div>
</template>
<script>


import {inject} from "vue";
import {Link} from '@inertiajs/vue3'
import BlankLayout from "../../../Layouts/BlankLayout.vue";

import {
    CheckIcon,
    XMarkIcon

} from '@heroicons/vue/24/solid'

export default {
    name: "RealtySchool",
    layout: BlankLayout,
    components: {
        Link,
        CheckIcon,
        XMarkIcon,
    },
    props: {
        users: Array
    },
    computed() {


    },
    setup(props) {



        return {


        }
    },
    methods: {

    }
}
</script>
