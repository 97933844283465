<script setup lang="ts">
</script>

<template>

    <section class="social-metrics">

        <h2>What is Incredibility?</h2>

        <div class="w-full max-w-7xl mx-auto">

            <vue-plyr>
                <div class="plyr__video-embed ">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/2p9HxNlvT30"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen allowtransparency></iframe>
                </div>
            </vue-plyr>


        </div>
        <h2 class="mt-28">If your social media isn’t on point, you ARE losing listings.</h2>
        <div class="metrics">
            <div class="metric">
                <span>2 in 3</span>
                <p>people said they would definitely check a realtor’s social pages before enlisting their services.</p>
            </div>
            <div class="metric">
                <span>71%</span>
                <p>believe a social media page which isn’t being regularly updated is a red flag.</p>
            </div>
            <div class="metric">
                <span>74%</span>
                <p>who follow one realtor, also follow at least one more.</p>
            </div>
        </div>

        <p class="text-xl font-semibold text-inc-blue mt-16 max-w-5xl mx-auto">That means your prospects will not only
            study your social media accounts,
            but will compare them to your competitors’. It also means the agent whose pages look great,
            feature valuable content and are regularly updated has an immediate advantage in the race
            to win business.
        </p>
    </section>
</template>

<style scoped lang="postcss">


.social-metrics {

    @apply text-center bg-[#F8F9FB];
    @apply py-10;

    @screen md {
        @apply py-10;
    }

    @screen lg {
        @apply pt-[155px] pb-[140px];
    }

    h2 {
        @apply text-2xl leading-[120%] font-semibold mb-16;

        @screen md {
            @apply text-3xl;
        }
        @screen xl {
            @apply text-42;
        }
    }

    .metrics {
        @apply justify-center items-center gap-10;
        @apply grid grid-cols-1;

        @screen md {
            @apply flex flex-row;
        }

        .metric {
            @apply text-center text-sm leading-normal text-site-gray relative;

            @screen md {
                @apply text-left text-17;
            }

            @screen lg {
                @apply max-w-sm;
            }

            &:before {
                content: "";
                @apply block bg-white rounded-full absolute;
                @apply top-0 -translate-y-5 left-1/2 -translate-x-1/2 w-20 h-20;

                @screen md {
                    @apply top-0 left-0 -translate-x-10 w-[169px] h-[169px];
                }
            }

            span,
            p {
                @apply relative;
            }

            span {
                @apply font-semibold text-3xl;
                @screen md {
                    @apply text-5xl;
                }
                @screen lg {
                    @apply text-80;
                }
            }

            &:first-child span {
                background: linear-gradient(88.68deg, #00B8FF 1%, #01C6F0 96.83%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &:nth-child(2) span {
                background: linear-gradient(89.51deg, #01CEE7 -1.07%, #01DCD6 96.66%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }

            &:last-child span {
                background: linear-gradient(270deg, #01F3BB 0%, #01E6C9 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
            }
        }
    }
}</style>